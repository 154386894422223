export const config = {
  java_api_url: "https://client.evote81.dltc.spbu.ru/api",
  auth_api_url: "https://client.evote81.dltc.spbu.ru/auth",
  client_url: "https://client.evote81.dltc.spbu.ru",
  enable_esia: false,
  ws_connect: "wss://client.evote81.dltc.spbu.ru/ws",
  ws_pass: "client",
  ws_user: "client",
  type: "main",
  realm: "REALM_CRYPTOVECHE",
};
