import React, { useEffect, useState } from "react";
import CardQuestionGraphNameColumnRow from "./CardQuestionGraphNameColumn/CardQuestionGraphNameColumnRow";
import CardQuestionHorizontalGraphRow from "./CardQuestionHorizontalGraph/CardQuestionHorizontalGraphRow";
import CardQuestionVerticalGraphRow from "./CardQuestionVerticalGraph/CardQuestionVerticalGraphRow";

const DetailsVotesPageResultVotesCardQuestionGraphRow = (props) => {
    const stub = false; // 2) Без испорченых биллютеней - опция организации 


  const { answersTemplateRow, numInvalid, votedUsers } = props;

  const [showGraphTypeVertical, setShowGraphTypeVertical] = useState(true);
  const [showGraphTypeHorizontal, setShowGraphTypeHorizontal] = useState(false);

  const fixedColors = [
    "#4ED4A9",
    "#F9C521",
    "#FF4970",
    "#0084FE",
    "#373C4E"
  ];

  const resultWithColor = answersTemplateRow.map(function (item, i) {
    return i > 4
      ? { ...item, color: randomColorRow() }
      : { ...item, color: fixedColors[i] };
  });

  function randomColorRow() {
    let x = Math.floor(Math.random() * 256);
    let y = Math.floor(Math.random() * 256);
    let z = Math.floor(Math.random() * 256);
    return "rgb(" + x + "," + y + "," + z + ")";
  }

  useEffect(() => {
    if (true || answersTemplateRow.length > 3) {
      setShowGraphTypeHorizontal(true);
      setShowGraphTypeVertical(false);
    } else {
      setShowGraphTypeHorizontal(false);
      setShowGraphTypeVertical(true);
    }
  }, [answersTemplateRow.length]);

  return (
    <div className={"details-votes-page-result-card-graph__wrapper"}>
      {showGraphTypeHorizontal && (
        <CardQuestionHorizontalGraphRow
          resultVote={resultWithColor}
          numInvalid={numInvalid}
          votedUsers={votedUsers}
        />
      )}
      {showGraphTypeVertical && (
        <CardQuestionVerticalGraphRow
          resultVote={resultWithColor}
          numInvalid={numInvalid}
          votedUsers={votedUsers}
        />
      )}
      <div className={"details-votes-page-result-card-graph__column-list"}>
        {resultWithColor.map((item, i) => {
          return (
            <CardQuestionGraphNameColumnRow
              key={i}
              nameColumn={item.title}
              colorSquare={item.color}
              votedPercent={
                item.columns[0].favor > 0
                  ? ((item.columns[0].favor / votedUsers) * 100).toFixed(1)
                  : 0
              }
            />
          );
        })}

        {stub && (
          <CardQuestionGraphNameColumnRow
            nameColumn={"Недействительные бюллетени"}
            colorSquare={"#9FA1A8"}
            votedPercent={0}
          />
        )}
      </div>
    </div>
  );
};
export default DetailsVotesPageResultVotesCardQuestionGraphRow;
