import { useState, useEffect } from "react";
import sorting_modal_close_button from "../../img/VotesPageBlockFilterModal_close_button.svg";
import * as Users from "../../Api/Users";
import { Validation } from "../../utils/Validation/Validation";

const AddNewUser = ({ requestHelper, setNotFoundedUsers }) => {
  console.log("requestHelper in add user: ", requestHelper);
  const [addNewUser, setAddNewUser] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const userEmail = Validation();
  const userName = Validation();
  const userSurname = Validation();
  const userLastName = Validation();

  useEffect(() => setDisableSubmit(false), []);

  const addNewUserHandler = () => {
    setDisableSubmit(true); // блокируем кнопку

    const body = {
      first_name: userName.value,
      last_name: userSurname.value,
      email: userEmail.value,
    };

    if (userEmail.value === "") {
      userEmail.setErrorMessage("Заполните это поле.");
    }

    if (userName.value === "") {
      userName.setErrorMessage("Заполните это поле.");
    }

    if (userSurname.value === "") {
      userSurname.setErrorMessage("Заполните это поле.");
    }

    if (
      userEmail.value === "" ||
      userName.value === "" ||
      userSurname.value === ""
    ) {
      setDisableSubmit(false);
      return;
    }

    requestHelper(Users.addNewUser, body).then((res) => {
      console.log("userEmail errorMessage", userEmail.errorMessage);
      setFormErrorMessage("");
      if (res.status && !res.ok) {
        setDisableSubmit(false);
        setFormErrorMessage(
          `Ошибка при добавлении пользователя. Код ошибки: ${res.status}`
        );
        return;
      }

      setAddNewUser(false);
    });
    setNotFoundedUsers && setNotFoundedUsers("");
  };

  useEffect(() => {
    if (addNewUser) {
      document.body.classList.add("body-overlay");
      return;
    }

    document.body.classList.remove("body-overlay");
  }, [addNewUser]);

  return (
    <>
      <div style={{ display: "flex", marginBottom: 20 }}>
        <button
          onClick={() => {
            setAddNewUser(!addNewUser);
          }}
          className="group-users-select-name-group__button"
        >
          Добавить нового пользователя
        </button>
      </div>

      {addNewUser && (
        <div className="group-users-select-name-group__add-users-form form-add-user">
          <img
            alt={""}
            src={sorting_modal_close_button}
            onClick={() => setAddNewUser(false)}
            style={{
              width: 14,
              cursor: "pointer",
              position: "absolute",
              top: 25,
              right: 35,
            }}
          />

          <h2 className="admins-settings__heading" style={{ marginBottom: 20 }}>
            Добавить нового пользователя
          </h2>
          <div className="add-new-vote__name-new-vote">
            <label className="add-new-vote__name-new-vote-label">
              Email
              <span className="add-new-vote__red-star"> *</span>
            </label>
            <input
              type="email"
              className="add-new-vote__name-new-vote-field"
              placeholder="Введите почту"
              name="user-name"
              value={userEmail.value}
              onChange={userEmail.onChange}
              required
            />
            {userEmail.errorMessage && (
              <p className="error-text">{userEmail.errorMessage}</p>
            )}
          </div>

          <div className="add-new-vote__name-new-vote">
            <label className="add-new-vote__name-new-vote-label">
              Имя
              <span className="add-new-vote__red-star"> *</span>
            </label>
            <input
              type="text"
              className="add-new-vote__name-new-vote-field"
              placeholder="Введите имя"
              name="user-name"
              value={userName.value}
              onChange={userName.onChange}
            />
            {userName.errorMessage && (
              <p className="error-text">{userName.errorMessage}</p>
            )}
          </div>

          <div className="add-new-vote__name-new-vote">
            <label className="add-new-vote__name-new-vote-label">
              Фамилия
              <span className="add-new-vote__red-star"> *</span>
            </label>
            <input
              type="text"
              className="add-new-vote__name-new-vote-field"
              placeholder="Введите фамилию"
              name="user-surname"
              value={userSurname.value}
              onChange={userSurname.onChange}
            />
            {userSurname.errorMessage && (
              <p className="error-text">{userSurname.errorMessage}</p>
            )}
          </div>

          <div className="add-new-vote__name-new-vote">
            <label className="add-new-vote__name-new-vote-label">
              Отчество
            </label>
            <input
              type="text"
              className="add-new-vote__name-new-vote-field"
              placeholder="Введите отчество"
              name="user-name"
              value={userLastName.value}
              onChange={userLastName.onChange}
            />
            {userLastName.errorMessage && (
              <p className="error-text">{userLastName.errorMessage}</p>
            )}
          </div>

          {formErrorMessage && (
            <p
              className="error-text"
              style={{ marginBottom: 25, fontSize: 14 }}
            >
              {formErrorMessage}
            </p>
          )}

          <button
            onClick={addNewUserHandler}
            type="button"
            className={"add-new-vote-question-type__add-vote-btn"}
            style={{ width: "100%", marginBottom: 20 }}
            disabled={disableSubmit}
          >
            Добавить
          </button>

          {/* {addNewError && (
            <p style={{ marginTop: 15, color: "red" }}>Что-то пошло не так</p>
          )} */}
        </div>
      )}
    </>
  );
};

export default AddNewUser;
