import React, { useEffect, useState } from "react";
import './DetailsVoteResults.css';
import DetailsVotesPageResultQuestionRow from '../DetailsVotesPageResultQuestionRow/DetailsVotesPageResultQuestionRow';
import DetailsVotesPageResultQuestionGrid from '../DetailsVotesPageResultQuestionGrid/DetailsVotesPageResultQuestionGrid';
import * as Events from "../../Api/Events";
import DetailsVoteResultsSendOpinionModal
    from "./DetailsVoteResultsSendOpinionModal/DetailsVoteResultsSendOpinionModal";
import {Validation} from "../../utils/Validation/Validation";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import useShowSignProtocolButton from "../../utils/CustomHooks/useShowSignProtocolButton/useShowSignProtocolButton";

const DetailsVoteResults = (props) => {

    const {
        constants,
        currentEventData,
        requestHelper,
        
    } = props;

    const {currentUser, runUpdateDataEvents} = useCurrentUserContext()
    const isOpinionText = Validation();
    const [questionsTemplateRow, setQuestionsTemplateRow] = useState([]);
    const [questionsTemplateGrid, setQuestionsTemplateGrid] = useState([]);
    const [isActiveSendOpinionModal, setActiveSendOpinionModal] = useState(false);
    const [isShowSignProtocol, setShowSignProtocol] = useShowSignProtocolButton(currentEventData, currentUser);
    const [isMessageError, setMessageError] = useState('');
    const [isLoading, setLoading] = useState('');
    const [currentEventDataProtocol, setCurrentEventDataProtocol] = useState('')

    // const findCurrentEventProtocol = () => {
    //     for (let key in currentEventData?.config?.event_template_links) {
    //         if (currentEventData?.id === key) {
    //             return setCurrentEventDataProtocol(currentEventData?.config?.event_template_links[key]);
    //         }
    //     }
    // }

    const onHandleRecreateProtocol = (isLoadValue) => {
        setLoading(isLoadValue)
        requestHelper(Events.getStartRegenerateProtocol, currentEventData?.id)
            .then((data) => {
                if (data.status === 'ok') {
                    setLoading('');
                   console.log(data)
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                setLoading('')
            })
    }

    const onHandleSingProtocol = (valueLoad) => {
        setLoading(valueLoad)
        const body = {
            id: currentEventData?.id,
            comment: ""
        }
        requestHelper(Events.postSignProtocol, body)
            .then((data) => {
                if (data.status === 'ok') {
                    setLoading('');
                    runUpdateDataEvents();
                    console.log(data)
                }
            })
            .catch((err) => {
                throw new Error(err.message);
            })
            .finally(() => {
                setLoading('')
            })
    }

    const onHandleSingProtocolWithOpinion = (valueLoad, valueOpinion) => {
        if (valueOpinion === '') {
            setMessageError('Необходимо ввести текст!')
        } else {
            setLoading(valueLoad)
            closeModal()
            const body = {
                id: currentEventData?.id,
                comment: valueOpinion
            }
            requestHelper(Events.postSignProtocol, body)
                .then((data) => {
                    if (data.status === 'ok') {
                        setLoading('');
                        runUpdateDataEvents();
                        console.log(data)
                    }
                })
                .catch((err) => {
                    throw new Error(err.message);
                })
                .finally(() => {
                    setLoading('')
                })
        }
    }

    const closeModal = () => {
        setActiveSendOpinionModal(false);
        setMessageError('');
    }

    function templateRow(questions) {
        const filteredQuestions = questions.filter(e => e.template === 'ynq' || e.template === 'none' || e.template === 'position_single' || e.template === 'position_multiple' || e.template === 'same_positions');
        setQuestionsTemplateRow(filteredQuestions);
    }

    function templateGrid(questions) {
        const filteredQuestions = questions.filter(e => e.template === 'grid' || e.template === 'radio_grid');
        setQuestionsTemplateGrid(filteredQuestions);
    }

    useEffect(() => {
        let isMounted = true;
            isMounted && setCurrentEventDataProtocol(currentEventData?.protocol?.link)
        return () => {
            isMounted = false;
        }
    },[currentEventData])

    useEffect(() => {
        if (currentEventData?.results?.questions.length !== 0) {
            templateRow(currentEventData?.results?.questions);
            templateGrid(currentEventData?.results?.questions);
        }
    }, [currentEventData?.results?.questions]);

    return (
        <div className="details-vote-results__container">
            <h3 className="details-vote-questions__title-mobile">{constants.DETAILS_VOTE.RESULTS_TITLE}</h3>
            <div className="details-vote-results__recreate-protocol-title">
                <h3 className="details-vote-questions__title">{constants.DETAILS_VOTE.RESULTS_TITLE}</h3>
                <div className="details-vote-results__buttons-block">
                    <div className="details-vote-results__recreate-protocol-load-protocol">
                        <a href={currentEventDataProtocol} className="details-vote-questions__export-protocol-btn">{constants.DETAILS_VOTE.RESULTS_EXPORT_DOC}</a>
                        <button onClick={() => onHandleRecreateProtocol('recreate')}
                                className={isLoading === 'recreate' ? "details-vote-questions__recreate-protocol-btn active" : "details-vote-questions__recreate-protocol-btn"}>
                            {isLoading === 'recreate' ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}` : `${constants.DETAILS_VOTE.RESULTS_RECREATE_PROTOCOL}`}
                        </button>
                    </div>
                    {isShowSignProtocol && <div className="details-vote-results__recreate-protocol-load-protocol">
                        <button onClick={() => onHandleSingProtocol('sign')}
                            className={isLoading === 'sign' ? "details-vote-questions__sign-protocol-btn active" : "details-vote-questions__sign-protocol-btn"}>
                            {isLoading === 'sign' ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}` : `${constants.DETAILS_VOTE.RESULTS_SIGN_PROTOCOL_VOTE}`}
                        </button>
                        <button onClick={() => setActiveSendOpinionModal(true)}
                            className={isLoading === 'signModal' ? "details-vote-questions__send-opinion-btn active" : "details-vote-questions__send-opinion-btn"}>
                            {isLoading === 'signModal' ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}` : `${constants.DETAILS_VOTE.RESULTS_SEND_MY_OPINION}`}
                        </button>
                    </div>}
                </div>
            </div>
            {questionsTemplateRow.length !== 0 && (
                <>
                    {questionsTemplateRow.map((question) => (
                        <DetailsVotesPageResultQuestionRow
                            key={question.id}
                            question={question}
                            currentEventData={currentEventData}
                            constants={constants}
                        />
                    ))}
                </>
            )}
            {questionsTemplateGrid.length !== 0 && (
                <>
                    {questionsTemplateGrid.map((question) => (
                        <DetailsVotesPageResultQuestionGrid
                            key={question.id}
                            question={question}
                            constants={constants}
                        />
                    ))}
                </>
            )}
            <DetailsVoteResultsSendOpinionModal
                isOpinionText={isOpinionText}
                isActiveSendOpinionModal={isActiveSendOpinionModal}
                closeModal={closeModal}
                constants={constants}
                isMessageError={isMessageError}
                onHandleSingProtocolWithOpinion={onHandleSingProtocolWithOpinion}
            />
        </div>
    )
}
export default DetailsVoteResults;